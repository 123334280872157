
import React, { Component } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  // Highlight,
  ClearRefinements,
  RefinementList,
  Configure,
  NumericMenu,
  HierarchicalMenu,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import './App.css';

const searchClient = algoliasearch(
  'J9N5RD8RD1',
  'cf96ad439aa0323ad3ee449f488d35fc'
);

// Update the App component
class App extends Component {
  render() {
    const searchParameters = {
      hitsPerPage: 6,
      distinct: true,
      disjunctiveFacetsRefinements: {
        brand: ['Samsung', 'Apple']
      },
    }
    return (
      <div className="ais-InstantSearch">
        <h1>Item Search Demo</h1>
        <InstantSearch
          indexName="test_items"
          searchClient={searchClient}
          searchParameters={searchParameters}
        >
          <div className="left-panel">
            <ClearRefinements />
            <h2>カテゴリー</h2>
            <HierarchicalMenu
              attributes={[
                'categories.lvl0',
                'categories.lvl1',
                'categories.lvl2',
                'categories.lvl3',
              ]}
            />
            <h2>オプション1</h2>
            <RefinementList attribute="option1" />
            <Configure hitsPerPage={8} />
            <h2>オプション2</h2>
            <RefinementList attribute="option2" />
            <Configure hitsPerPage={8} />
            <h2>男女</h2>
            <RefinementList attribute="production" />
            <Configure hitsPerPage={8} />
            <h2>価格</h2>
            <NumericMenu
              attribute="price"
              items={[
                { label: '<= ¥1,000', end: 1000 },
                { label: '¥1,000 - ¥10,000', start: 1000, end: 10000 },
                { label: '¥10,000 - ¥50,000', start: 10000, end: 50000 },
                { label: '>= ¥50,000', start: 50000 },
              ]}
            />
            <h2>原産地</h2>
            <RefinementList attribute="original" />
            <Configure hitsPerPage={8} />
            <h2>素材：TODO</h2>
            <RefinementList attribute="category" />
            <Configure hitsPerPage={8} />
          </div>
          <div className="right-panel">
            <SearchBox />
            <Hits hitComponent={Hit} />
            <Pagination />
          </div>
        </InstantSearch>
      </div>
    );
  }
}

function Hit(props) {
  console.log(props)
  const url = `http://msct.shop20.makeshop.jp/shopdetail/${props.hit.brandcode}/`
  return (
    <article>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <p>
          <code>{JSON.stringify(props.hit).slice(0, 100)}...</code>
        </p>
      </a>
    </article>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default App;
